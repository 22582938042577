const onInserted = [];

export const addEventListenerOnInserted = (callback) => {
  onInserted.push(callback);
};

(() => {
  const BWDContactForm = () => {
    const classes = {
      modifiers: {
        open: "mod--open",
        close: "mod--hide",
        active: "mod--active",
        blackColor: "mod--black-color",
        disableScroll: "mod--disable-scroll",
        openPrivacy: "mod--openPrivacy",
        invalidField: "mod--invalidField",
        maxLength: "mod--warning",
        selectedOption: "mod--selected",
        moreMargin: "bwd-contact-form__modal-close--moreMargin",
      },
      mainComponent: ".bwd-contact-form",
      wrapper: ".bwd-contact-form__wrapper",
      contactUs: ".js-contact-contactUs",
      dropdown: ".js-contact-dropdown",
      contactModal: ".js-contact-modal",
      closeModal: ".js-contact-modal-close",
      welcoming: {
        modal: ".js-contact-welcoming",
        btn: ".js-contact-welcoming-btn",
      },
      steps: {
        modal: ".js-contact-steps",
        categories: ".js-contact-steps-categories",
        separators: ".js-contact-separator",
        stepOne: ".js-contact-stepOne",
        filters: ".js-contact-destination",
        stepTwo: ".js-contact-stepTwo",
        guestsFilters: ".js-contact-guest",
        stepThree: ".js-contact-stepThree",
        weddingDate: ".js-contact-date",
        stepFour: ".js-contact-stepFour",
        backBtn: ".js-contact-back",
        continueBtn: ".js-contact-continue",
        contactName: ".js-contact-name",
        contactSurname: ".js-contact-surname",
        contactPhone: ".js-contact-phone",
        contactEmail: ".js-contact-email",
        videoCall: ".js-video-call",
        privacyCheckbox: ".js-contact-privacy",
        acceptConditions: ".js-contact-acceptConditions",
        commentArea: ".js-contact-writeComment",
        commentAreaCounter: ".js-contact-writeComment-counter",
        destinationSelect: ".js-destination-select",
        hotelSelect: ".js-hotel-select",
      },
      thankYou: {
        modal: "thankYou-modal",
        mail: ".js-thankYou-mail",
      },
      errorMessage: ".js-contact-error",
      errorSendMessage: ".js-form-send-error",
      editMode: "isAuthor",
    };

    const langValue = {
      arabic: "ar-AE",
      enArabic: "en-AE",
    };

    const props = {
      groupedData: [],
      data: [],
    };

    let userProfileCountryCode = "";

    const formData = new URLSearchParams();
    formData.set("Case___Origin", "WEDDINGS");
    formData.set("Case___Channel__c", "WEB");
    formData.set("Case___MyBarcelo__c", "0");
    formData.set("Case___Type", "2");
    formData.set("Case___Category__c", "8");
    formData.set("Case___Subcategory__c", "11");
    formData.set("Case___Hotel__c", "Hotel__c");
    formData.set("Hotel__c___Id", "Hotel__c___ID_SynXis__c___RO");

    const getData = async (componentWrapper) => {
      const path = componentWrapper?.dataset?.path;
      try {
        const response = await fetch(path);
        if (!response.ok) {
          console.error("bwd-contact-form: server responded with error response:", response);
          return;
        }
        props.data = await response.json();
      } catch (error) {
        console.error("bwd-contact-form: error loading data:", error);
      }
    };

    const groupData = async (componentWrapper) => {
      await getData(componentWrapper);
      props.groupedData = props.data.reduce((acc, item) => {
        if (!acc[item.country]) {
          acc[item.country] = [];
        }
        acc[item.country].push({ hotelName: item.hotelName, id: item.id });
        return acc;
      }, {});
    };

    const selectHandler = (destinationSelect, hotelSelect) => {
      const countries = Object.keys(props.groupedData);
      for (const country of countries) {
        const option = document.createElement("option");

        option.value = country;
        option.textContent = country;

        destinationSelect.appendChild(option);
      }

      destinationSelect.addEventListener("change", () => {
        if (destinationSelect.value !== "") {
          destinationSelect.classList.add(classes.modifiers.selectedOption);
          destinationSelect.options[0].disabled = true;
          const hotels = props.groupedData[destinationSelect.value];

          while (hotelSelect.options.length > 1) {
            hotelSelect.remove(1);
          }

          for (const hotel of hotels) {
            const hotelOption = document.createElement("option");

            hotelOption.value = hotel.hotelName;
            hotelOption.textContent = hotel.hotelName;
            hotelOption.dataset.id = hotel.id;
            hotelSelect.appendChild(hotelOption);
          }

          hotelSelect.disabled = false;
        }
      });

      hotelSelect.addEventListener("change", () => {
        if (hotelSelect.value !== "") {
          hotelSelect.classList.add(classes.modifiers.selectedOption);
        } else {
          hotelSelect.classList.remove(classes.modifiers.selectedOption);
        }
      });
    };

    const inactiveAllStepNames = (contactStepCategories, contactStepSeparators) => {
      contactStepCategories.forEach((category) => {
        category.classList.remove(classes.modifiers.active);
      });

      contactStepSeparators.forEach((separator) => {
        separator.classList.remove(classes.modifiers.active);
      });
    };

    const addBlackColor = (contactStepCategories, contactStepSeparators, stepCounter, separatorCounter) => {
      if (contactStepCategories[stepCounter] && contactStepSeparators[separatorCounter]) {
        contactStepCategories[stepCounter].classList.add(classes.modifiers.blackColor);
        contactStepSeparators[separatorCounter].classList.add(classes.modifiers.blackColor);
      }

      if (window.innerWidth < 1023) {
        if (contactStepCategories[stepCounter] && contactStepSeparators[separatorCounter]) {
          inactiveAllStepNames(contactStepCategories, contactStepSeparators);

          contactStepCategories[stepCounter].classList.add(classes.modifiers.active);
          contactStepSeparators[separatorCounter].classList.add(classes.modifiers.active);
        }
      }
    };

    // Add 1 to get the index of the next element when moving backwards
    const removeBlackColor = (contactStepCategories, contactStepSeparators, stepCounter, separatorCounter) => {
      if (contactStepCategories[stepCounter + 1] && contactStepSeparators[separatorCounter + 1]) {
        contactStepCategories[stepCounter + 1].classList.remove(classes.modifiers.blackColor);
        contactStepSeparators[separatorCounter + 1].classList.remove(classes.modifiers.blackColor);
      }

      if (window.innerWidth < 1023) {
        if (contactStepCategories[stepCounter + 1] && contactStepSeparators[separatorCounter + 1]) {
          contactStepCategories[stepCounter + 1].classList.remove(classes.modifiers.active);
          contactStepSeparators[separatorCounter + 1].classList.remove(classes.modifiers.active);
          contactStepCategories[stepCounter].classList.add(classes.modifiers.active);
        }
      }
    };

    const reset = (component, contactWelcomingModal, contactStepOne, contactDropdown, contactStepCategories, contactStepSeparators, contactBackBtn, html) => {
      // Hide all visible elements in the modal
      const modOpens = component.querySelectorAll(`.${classes.modifiers.open}`);

      if (contactWelcomingModal) {
        if (modOpens && modOpens.length > 0) {
          modOpens.forEach((modOpen) => {
            modOpen.classList.remove(classes.modifiers.open);

            if (contactStepOne) {
              contactWelcomingModal.classList.add(classes.modifiers.open);
              contactStepOne.classList.add(classes.modifiers.open);
            }
          });
        }

        contactWelcomingModal.classList.remove(classes.thankYou.modal);
      }

      // Display the dropdown of contact form
      contactDropdown.classList.remove(classes.modifiers.close, classes.modifiers.open);

      // Reset all step statuses
      if (contactStepCategories && contactStepCategories.length > 0 && contactStepSeparators && contactStepSeparators.length > 0) {
        contactStepSeparators.forEach((separator) => {
          separator.classList.remove(classes.modifiers.blackColor, classes.modifiers.active);
        });

        contactStepCategories.forEach((category, index) => {
          if (index > 0) {
            category.classList.remove(classes.modifiers.blackColor, classes.modifiers.active);
            category.classList.remove(classes.modifiers.blackColor);
          } else {
            category.classList.add(classes.modifiers.active);
          }
        });

        if (contactBackBtn) {
          contactBackBtn.setAttribute("disabled", "");
        }
      }

      html.classList.remove(classes.modifiers.disableScroll);
    };

    const stepOneFilters = (contactStepOneFilters) => {
      if (contactStepOneFilters && contactStepOneFilters.length > 0) {
        contactStepOneFilters.forEach((filter) => {
          filter.addEventListener("click", () => {
            filter.classList.toggle(classes.modifiers.active);
          });
        });
      }
    };

    const setDestination = (destinationValue, hotelSelect) => {
      let destination = "";
      if (destinationValue && hotelSelect.value) {
        destination = destinationValue + ", " + hotelSelect.value;
        const selectedHotelOption = hotelSelect.options[hotelSelect.selectedIndex];
        const selectedHotelId = selectedHotelOption.dataset.id;
        formData.set("Hotel__c___ID_SynXis__c___RO", selectedHotelId);
      }

      return destination;
    };

    const validateSelect = (data) => data !== null && data !== "" && data !== undefined;

    const validateDate = (date) => {
      let isValidDate = false;

      if (date.value && date.value.trim() !== "") {
        const inputDate = new Date(date.value);

        date.classList.remove(classes.modifiers.invalidField);

        if (inputDate > new Date()) {
          date.classList.remove(classes.modifiers.invalidField);
          isValidDate = true;
        } else {
          date.classList.add(classes.modifiers.invalidField);
        }
      } else {
        date.classList.add(classes.modifiers.invalidField);
      }

      return isValidDate;
    };

    const validateData = (name, surname, email, phone) => {
      name.value = name.value.trim();
      surname.value = surname.value.trim();
      email.value = email.value.trim();
      phone.value = phone.value.trim();

      let isValidData = true;
      if (!name.value || !surname.value || !email.value || !phone.value) {
        isValidData = false;
      }

      // nameRegex require at least one character and does not allow '&', '<', '>', '"', or ASCII control characters.
      const nameRegex = /^[^&<>"\0-\037]+$/;
      if (!nameRegex.test(name.value)) {
        name.classList.add(classes.modifiers.invalidField);
        isValidData = false;
      } else {
        name.classList.remove(classes.modifiers.invalidField);
      }

      if (!nameRegex.test(surname.value)) {
        surname.classList.add(classes.modifiers.invalidField);
        isValidData = false;
      } else {
        surname.classList.remove(classes.modifiers.invalidField);
      }

      const regex = /^[^@]+@[^@]+\.[^@]+$/;
      if (!regex.test(email.value)) {
        email.classList.add(classes.modifiers.invalidField);
        isValidData = false;
      } else {
        email.classList.remove(classes.modifiers.invalidField);
      }

      const phoneRegex = /^\d+$/;
      if (!phoneRegex.test(phone.value)) {
        phone.classList.add(classes.modifiers.invalidField);
        isValidData = false;
      } else {
        phone.classList.remove(classes.modifiers.invalidField);
      }
      return isValidData;
    };

    const validateGuestCapacity = (guestCapacity) => {
      let isValidGuestCapacity;

      if (guestCapacity.value != "") {
        const guestCapacityInt = parseInt(guestCapacity.value);

        if (!isNaN(guestCapacityInt) && guestCapacityInt >= 0 && guestCapacityInt <= 9999) {
          guestCapacity.classList.remove(classes.modifiers.invalidField);
          isValidGuestCapacity = true;
        } else {
          guestCapacity.classList.add(classes.modifiers.invalidField);
          isValidGuestCapacity = false;
        }
      } else {
        guestCapacity.classList.add(classes.modifiers.invalidField);
        isValidGuestCapacity = false;
      }

      return isValidGuestCapacity;
    };

    const createCaseSalesForce = async (elemInput) => {
      const isProd = elemInput?.getAttribute("data-productionenviroment") && elemInput.getAttribute("data-productionenviroment") !== "false";
      const url = isProd ? "/pxy/secure/Clic2Salesforce/" : "/pxy/test/secure/Clic2Salesforce/";

      const $form = document.querySelector(classes.wrapper);
      let dataCountry = $form.getAttribute("data-country") ? $form.getAttribute("data-country").toLowerCase() : "";
      dataCountry = "ww" === dataCountry ? "" : dataCountry;

      formData.set("Case___CountryOrigin__c", userProfileCountryCode || dataCountry);
      const resp = await fetch(url, {
        body: formData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        method: "POST",
      });
      if (!resp.ok) {
        throw new Error(`Server returned error (${resp.status}): ${resp.statusText}`);
      }

      const respText = await resp.text();
      try {
        JSON.parse(respText);
        return;
      } catch (_) {}

      const respHtml = new DOMParser().parseFromString(respText, "text/html");
      const respJsonText = respHtml.getElementById("result")?.innerText;
      const respJson = JSON.parse(respJsonText);
      if (respJson.result !== "ok") {
        throw new Error(`Petition denied: ${respJson.message}`);
      }
    };

    const formattedDate = (d) => {
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const day = String(d.getDate()).padStart(2, "0");
      const year = String(d.getFullYear());
      return year + "/" + month + "/" + day;
    };

    const warnMaxLengthTextarea = (comment) => {
      if (comment) {
        const commentAreaCounter = document.querySelector(classes.steps.commentAreaCounter);
        const maxLength = comment.getAttribute("maxlength") ? comment.getAttribute("maxlength") : 0;

        comment.addEventListener("keyup", () => {
          const commentLength = comment.value.length;

          if (commentLength >= maxLength) {
            commentAreaCounter?.classList.add(classes.modifiers.maxLength);
          } else {
            commentAreaCounter?.classList.remove(classes.modifiers.maxLength);
          }
        });
      }
    };

    const showEmail = (email) => {
      const emailSection = document.querySelector(classes.thankYou.mail);
      const language = document.documentElement.lang;

      if (emailSection && emailSection.textContent.includes("[email]")) {
        const index = emailSection.textContent.indexOf("[email]");
        const emailSectionText = emailSection.textContent.substring(0, index);

        if (language == langValue.arabic || language == langValue.enArabic) {
          emailSection.textContent = email + " " + emailSectionText;
        } else {
          emailSection.textContent = emailSectionText + email;
        }
      }
    };

    /**
     * Updates the country preference defined in user profile.
     * @returns {Promise<void>} Promise that ends when the user CC is defined, or it has timed out.
     */
    const getUserProfileCountryPreference = async () => {
      // Iterative listen defined because bcl.contextHub.callWhenReady is surprisingly unreliable
      for (let i = 1; i <= 8; i *= 2) {
        const profile = sessionStorage.getItem("contextHub.profile");
        if (!profile) {
          await new Promise((resolve) => setTimeout(resolve, i * 1000));
          continue;
        }
        userProfileCountryCode = JSON.parse(profile).props.country_code?.trim()?.toLowerCase() || "";
        return;
      }
    };

    /**
     * Retrieves the phone that is linked with the country code provided, or US as fallback (both stored in the Contact Form).
     * @param {HTMLElement} elem Contact Form element.
     * @param {string} cc Country code.
     * @returns {string|undefined} The phone linked with the country code provided, or undefined if neither the one provided or US is defined.
     */
    const getPhoneFromCC = (elem, cc) => {
      const phone = Array.from(elem.getElementsByClassName("weddingPhoneCC")).find((elemPhoneData) => elemPhoneData.dataset.country.trim().toLowerCase() === cc)?.dataset?.phone;
      if (!phone && cc !== "us") {
        return getPhoneFromCC(elem, "us");
      }
      return phone;
    };

    const loadPhoneNumber = async (elem) => {
      await getUserProfileCountryPreference();
      const phone = getPhoneFromCC(elem, userProfileCountryCode || "us");
      if (!phone) {
        console.error("US wedding contact phone (fallback) is not defined");
        return;
      }

      const phoneHref = `tel:${phone.replace(/[- ()]/g, "")}`;
      Array.from(elem.querySelectorAll("a.weddingAnchorCC")).forEach((phoneAnchor) => {
        phoneAnchor.href = phoneHref;
        phoneAnchor.innerText = phone;
      });
    };

    const init = async () => {
      const header = document.querySelector("[data-wedding-contact-form-url]");

      if (!document.body.classList.contains("edit")) {
        if (header) {
          let webFragmentUrl = header.dataset.weddingContactFormUrl + "/jcr:content/root.html";

          if (document.body.classList.contains(classes.editMode)) {
            webFragmentUrl += "?wcmmode=disabled";
          }

          const fetchOptions = {
            method: "GET",
            headers: {
              "X-Requested-With": "XMLHttpRequest",
            },
          };

          try {
            const mainDiv = document.createElement("div");
            mainDiv.innerHTML = await (await fetch(webFragmentUrl, fetchOptions)).text();

            const parentComponent = document.querySelector("main") ? document.querySelector("main > .cmp-container") : document.body;
            parentComponent.appendChild(mainDiv);
            const component = parentComponent.getElementsByClassName("bwd-contact-form")[0];
            if (component) {
              onInserted.forEach((fn) => fn(component));
            }
          } catch (err) {
            console.error(err);
          }
        }
      }

      const contactForms = Array.from(document.querySelectorAll(classes.mainComponent));

      contactForms.forEach(async (component) => {
        const hotelTitle = document.querySelector("[data-hotel-title]")?.dataset?.hotelTitle;
        const componentWrapper = component.querySelector(classes.wrapper);
        const contactDropdown = component.querySelector(classes.dropdown);
        const contactUsBtn = component.querySelector(classes.contactUs);
        const contactModal = component.querySelector(classes.contactModal);
        const closeModalBtn = component.querySelector(classes.closeModal);
        const contactStepCategories = component.querySelectorAll(classes.steps.categories);
        const contactStepSeparators = component.querySelectorAll(classes.steps.separators);
        const formGuests = component.querySelector(classes.steps.guestsFilters);
        const destinationSelect = component.querySelector(classes.steps.destinationSelect);
        const hotelSelect = component.querySelector(classes.steps.hotelSelect);
        const html = document.querySelector("html");

        bcl.contextHub.callWhenReady(() => loadPhoneNumber(component), bcl.contextHub.props.nameProfile);

        await groupData(componentWrapper);
        selectHandler(destinationSelect, hotelSelect);

        const dataset = componentWrapper?.dataset;
        formData.set("Case___Subject", dataset?.subject);
        formData.set("Case___RecordTypeId", dataset?.recordTypeId);
        formData.set("Case___CustomerLanguage__c", dataset?.language);

        let stepWindowCounter = 1; // Counter to move to the next or previous step
        let stepCounter = 0; // Counter to color the step categories (max: 3)
        let separatorCounter = -1; // Counter to color the step categories (max: 2)

        if (contactDropdown) {
          // Open and close the dropdown
          contactDropdown.addEventListener("click", () => {
            contactDropdown.classList.toggle(classes.modifiers.open);
          });

          // Open the first modal (welcoming modal)
          if (contactUsBtn) {
            contactUsBtn.addEventListener("click", () => {
              if (contactDropdown.classList.contains(classes.modifiers.open)) {
                contactModal.classList.add(classes.modifiers.open);
                contactDropdown.classList.remove(classes.modifiers.open);
                contactDropdown.classList.add(classes.modifiers.close);
                html.classList.add(classes.modifiers.disableScroll);
              }
            });
          }

          const contactWelcomingModal = component.querySelector(classes.welcoming.modal);
          const contactBackBtn = component.querySelector(classes.steps.backBtn);
          const contactStepOne = component.querySelector(classes.steps.stepOne);
          const contactStepTwo = component.querySelector(classes.steps.stepTwo);

          // Close the modal
          closeModalBtn.addEventListener("click", () => {
            // Reset all counters
            stepWindowCounter = 1;
            stepCounter = 0;
            separatorCounter = -1;
            if (!hotelTitle) {
              reset(component, contactWelcomingModal, contactStepOne, contactDropdown, contactStepCategories, contactStepSeparators, contactBackBtn, html);
            } else {
              reset(component, contactWelcomingModal, contactStepTwo, contactDropdown, contactStepCategories, contactStepSeparators, contactBackBtn, html);
            }
            closeModalBtn.classList.remove(classes.modifiers.moreMargin);
          });

          const contactWelcomingBtn = component.querySelector(classes.welcoming.btn);
          const contactSteps = component.querySelector(classes.steps.modal);

          // Open the second modal (steps modal)
          if (contactWelcomingBtn) {
            contactWelcomingBtn.addEventListener("click", () => {
              if (contactWelcomingModal && contactWelcomingModal.classList.contains(classes.modifiers.open)) {
                contactWelcomingModal.classList.remove(classes.modifiers.open);
              }

              if (contactSteps) {
                contactSteps.classList.add(classes.modifiers.open);
                closeModalBtn.classList.add(classes.modifiers.moreMargin);

                if (!hotelTitle) {
                  contactStepOne.classList.add(classes.modifiers.open);
                } else {
                  stepWindowCounter++;
                  stepCounter++;
                  separatorCounter++;
                  contactStepTwo.classList.add(classes.modifiers.open);
                  addBlackColor(contactStepCategories, contactStepSeparators, stepCounter, separatorCounter);
                  const hotelId = document.querySelector("[data-hotel-id")?.dataset?.hotelId;
                  formData.set("Hotel__c___ID_SynXis__c___RO", hotelId);
                }
              }
            });

            if (contactSteps) {
              const contactContinueBtn = contactSteps.querySelector(classes.steps.continueBtn);
              const contactStepOneFilters = contactSteps.querySelectorAll(classes.steps.filters);
              const contactStepThree = contactSteps.querySelector(classes.steps.stepThree);
              const contactStepFour = contactSteps.querySelector(classes.steps.stepFour);
              const policies = contactSteps.querySelector(classes.steps.privacyCheckbox);
              const conditions = contactSteps.querySelector(classes.steps.acceptConditions);
              const weddDate = component.querySelector(classes.steps.weddingDate);
              const name = component.querySelector(classes.steps.contactName);
              const surname = component.querySelector(classes.steps.contactSurname);
              const phone = component.querySelector(classes.steps.contactPhone);
              const email = component.querySelector(classes.steps.contactEmail);
              const comment = component.querySelector(classes.steps.commentArea);
              const errorMessageStepOne = contactStepOne.querySelector(classes.errorMessage);
              const errorMessageStepFour = contactStepFour.querySelector(classes.errorMessage);
              const errorSendMessage = contactStepFour.querySelector(classes.errorSendMessage);

              // Notify the user when max length is reached
              warnMaxLengthTextarea(comment);

              // Step 1 - filters
              stepOneFilters(contactStepOneFilters);

              // Moving steps
              if (contactStepCategories && contactStepSeparators) {
                if (contactStepOne && contactStepTwo && contactStepThree && contactStepFour) {
                  if (contactContinueBtn && contactBackBtn) {
                    contactSteps.addEventListener("submit", (evt) => {
                      evt.preventDefault();
                    });

                    contactContinueBtn.addEventListener("click", async () => {
                      stepWindowCounter++;
                      stepCounter++;
                      separatorCounter++;

                      switch (stepWindowCounter) {
                        case 2:
                          if (validateSelect(setDestination(destinationSelect.value, hotelSelect))) {
                            contactBackBtn.removeAttribute("disabled");
                            contactStepOne.classList.remove(classes.modifiers.open);
                            contactStepTwo.classList.add(classes.modifiers.open);

                            addBlackColor(contactStepCategories, contactStepSeparators, stepCounter, separatorCounter);

                            if (errorMessageStepOne) {
                              errorMessageStepOne.classList.remove(classes.modifiers.open);
                            }
                          } else {
                            if (errorMessageStepOne) {
                              errorMessageStepOne.classList.add(classes.modifiers.open);
                            }

                            stepWindowCounter--;
                            stepCounter--;
                            separatorCounter--;
                          }
                          break;
                        case 3:
                          if (validateGuestCapacity(formGuests)) {
                            formData.set("Case___NumberAttendees__c", Number(formGuests.value));
                            contactStepTwo.classList.remove(classes.modifiers.open);
                            contactStepThree.classList.add(classes.modifiers.open);

                            addBlackColor(contactStepCategories, contactStepSeparators, stepCounter, separatorCounter);
                            if (hotelTitle) {
                              contactBackBtn.removeAttribute("disabled");
                            }
                          } else {
                            stepWindowCounter--;
                            stepCounter--;
                            separatorCounter--;
                          }
                          break;
                        case 4:
                          if (validateDate(weddDate)) {
                            formData.set("Case___EventDate__c", formattedDate(new Date(weddDate.value)));
                            contactStepThree.classList.remove(classes.modifiers.open);
                            contactStepFour.classList.add(classes.modifiers.open);

                            addBlackColor(contactStepCategories, contactStepSeparators, stepCounter, separatorCounter);
                          } else {
                            stepWindowCounter--;
                            stepCounter--;
                            separatorCounter--;
                          }
                          break;
                        case 5:
                          if (validateData(name, surname, email, phone) && policies.checked) {
                            formData.set("Case___ContactName__c", name.value);
                            formData.set("Case___SuppliedName", surname.value);
                            formData.set("Case___SuppliedPhone", phone.value);
                            formData.set("Case___SuppliedEmail", email.value);
                            formData.set("Case___Description", comment.value ? comment.value : "");

                            if (conditions.checked) {
                              formData.set("Case___OptInDate__c", formattedDate(new Date()));
                            }

                            try {
                              await createCaseSalesForce(componentWrapper);
                            } catch (err) {
                              errorSendMessage.classList.add(classes.modifiers.openPrivacy);
                              policies.classList.remove(classes.modifiers.invalidField);
                              errorMessageStepFour.classList.remove(classes.modifiers.openPrivacy);
                              console.error("Contact Form error:", err);

                              stepWindowCounter--;
                              stepCounter--;
                              separatorCounter--;
                              break;
                            }

                            errorSendMessage.classList.remove(classes.modifiers.openPrivacy);
                            contactSteps.classList.remove(classes.modifiers.open);
                            contactWelcomingModal.classList.add(classes.modifiers.open, classes.thankYou.modal);
                            showEmail(email.value);

                            if (errorMessageStepFour) {
                              policies.classList.remove(classes.modifiers.invalidField);
                              errorMessageStepFour.classList.remove(classes.modifiers.openPrivacy);
                            }

                            closeModalBtn.classList.remove(classes.modifiers.moreMargin);
                          } else {
                            if (errorMessageStepFour) {
                              if (policies.checked) {
                                policies.classList.remove(classes.modifiers.invalidField);
                                errorMessageStepFour.classList.remove(classes.modifiers.openPrivacy);
                              } else {
                                policies.classList.add(classes.modifiers.invalidField);
                                errorMessageStepFour.classList.add(classes.modifiers.openPrivacy);
                              }
                            }

                            stepWindowCounter--;
                            stepCounter--;
                            separatorCounter--;
                          }
                          break;
                      }

                      if (stepWindowCounter > 4) {
                        stepWindowCounter = 4;
                      }

                      if (stepCounter > contactStepCategories.length - 1) {
                        stepCounter = 3;
                      }

                      if (separatorCounter > contactStepSeparators.length - 1) {
                        separatorCounter = 2;
                      }
                    });

                    contactBackBtn.addEventListener("click", () => {
                      stepWindowCounter--;
                      stepCounter--;
                      separatorCounter--;

                      switch (stepWindowCounter) {
                        case 3:
                          contactStepThree.classList.add(classes.modifiers.open);
                          contactStepFour.classList.remove(classes.modifiers.open);

                          removeBlackColor(contactStepCategories, contactStepSeparators, stepCounter, separatorCounter);
                          break;
                        case 2:
                          contactStepTwo.classList.add(classes.modifiers.open);
                          contactStepThree.classList.remove(classes.modifiers.open);

                          removeBlackColor(contactStepCategories, contactStepSeparators, stepCounter, separatorCounter);
                          break;
                        case 1:
                          contactStepOne.classList.add(classes.modifiers.open);
                          contactStepTwo.classList.remove(classes.modifiers.open);

                          removeBlackColor(contactStepCategories, contactStepSeparators, stepCounter, separatorCounter);
                          break;
                      }

                      if (stepWindowCounter == 1) {
                        stepWindowCounter = 1;
                        contactBackBtn.setAttribute("disabled", "");
                      }

                      if (stepWindowCounter == 2 && hotelTitle) {
                        contactBackBtn.setAttribute("disabled", "");
                      }

                      if (stepCounter == 0) {
                        stepCounter = 0;
                      }

                      if (separatorCounter == -1) {
                        separatorCounter = -1;
                      }
                    });
                  }
                }
              }
            }
          }
        }
      });
    };

    init();
  };
  document.addEventListener("DOMContentLoaded", BWDContactForm);
})();
