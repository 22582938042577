const initCopyBtn = (btn) => {
  const textAfter = btn.dataset.textAfter?.trim() || "Copied!";
  const textBefore = btn.dataset.textBefore?.trim() || "Copy";

  btn.addEventListener("click", () => {
    const code = btn.closest(".bwd-discount-banner__wrapper")?.getElementsByClassName("bwd-discount-banner__code")[0]?.textContent?.replace(/\s+/g, " ")?.trim();
    if (!code) {
      return;
    }
    navigator.clipboard.writeText(code).catch((err) => console.error("Error copying to clipboard:", err));
    btn.disabled = true;
    btn.innerText = textAfter;
    setTimeout(() => {
      btn.disabled = false;
      btn.innerText = textBefore;
    }, 2000);
  });
};

const initShareBtn = (btn) => {
  btn.addEventListener("click", () => {
    const code = btn.closest(".bwd-discount-banner__wrapper")?.getElementsByClassName("bwd-discount-banner__code")[0]?.textContent?.replace(/\s+/g, " ")?.trim();
    if (!code) {
      return;
    }
    navigator.share({ text: code }).catch((err) => {
      if (err?.name !== "AbortError") {
        console.error("Error sharing code:", err);
      }
    });
  });
};
const banners = document.querySelectorAll(".bwd-discount-banner");
banners?.forEach((banner) => {
  const $copyBtn = banner.querySelector(".copy");
  if ($copyBtn) {
    initCopyBtn($copyBtn);
  }
  const $shareBtn = banner.querySelector(".share");
  if ($shareBtn) {
    initShareBtn($shareBtn);
  }
});
